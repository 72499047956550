import { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IconButton, Stack, useMediaQuery } from "@mui/material";

export const CalendarToolbar = (props) => {
  const {
    startingDate,
    date,
    onAddClick,
    onDateNext,
    onDatePrev,
    onDateToday,
    onViewChange,
    nrOfLocations,
    location,
    handleNextLocation,
    handlePrevLocation,
    meetingTypeIndex,
    changeMeetingType,
    ...other
  } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mobileView = !mdUp;

  const [initialDate, setInitialDate] = useState(startingDate);

  const dateMonth = format(initialDate ? initialDate : date, "MMMM");
  const dateDay = format(initialDate ? initialDate : date, "y");

  return (
    <Stack
      alignItems="center"
      flexWrap="wrap"
      justifyContent="center"
      flexDirection={{
        xs: "column",
        md: "row",
      }}
      {...other}
    >
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="center"
        direction="row"
        spacing={1}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "250px",
          }}
        >
          <IconButton
            onClick={() => {
              onDatePrev();
              setInitialDate(null);
            }}
            style={{
              height: "30px",
              width: "30px",
              marginTop: !mobileView && "-3px",
            }}
          >
            <ArrowDropDownIcon
              style={{
                color: "black",
                transform: "scale(1, 1.7)",
                rotate: "90deg",
                fontSize: mobileView ? 20 : 22,
              }}
            />
          </IconButton>
          <Stack
            style={{
              margin: "0 10px",
              display: "flex",
              flexDirection: "row",
              userSelect: "none",
            }}
          >
            <p
              style={{
                fontFamily: "tmwCustomFont",
                letterSpacing: 3,
                fontSize: mobileView ? 13 : 14,
                textTransform: "uppercase",
              }}
            >
              {dateMonth}
            </p>
            <p
              style={{
                fontFamily: "tmwCustomFont",
                letterSpacing: 3,
                fontSize: mobileView ? 13 : 14,
                textTransform: "uppercase",
                marginLeft: "7px",
              }}
            >
              {dateDay}
            </p>
          </Stack>
          <IconButton
            onClick={() => {
              onDateNext();
              setInitialDate(null);
            }}
            style={{
              height: "30px",
              width: "30px",
              marginTop: !mobileView && "-3px",
            }}
          >
            <ArrowDropDownIcon
              style={{
                color: "black",
                transform: "scale(1, 1.7)",
                rotate: "-90deg",
                fontSize: mobileView ? 20 : 22,
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

CalendarToolbar.propTypes = {
  children: PropTypes.node,
  date: PropTypes.instanceOf(Date).isRequired,
  onAddClick: PropTypes.func,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  onViewChange: PropTypes.func,
};
