import { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  TextField,
  Box,
  SvgIcon,
  Link,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { useAuth } from "src/hooks/use-auth";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { AlertContext } from "../../../components/alert/AlertContext";
import { CustomButton, CustomTextField } from "src/components/inputs";

const initialValues = {
  email: "",
  submit: null,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const Page = () => {
  const isMounted = useMounted();
  const { sendPasswordRenewalEmail } = useAuth();
  const { showAlert } = useContext(AlertContext);
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        if (isMounted()) {
          await sendPasswordRenewalEmail(values.email);
          showAlert(
            "If an account with that email exists, a password reset link has been sent.",
            "success"
          );
          helpers.setStatus({ success: true });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });

  usePageView();

  return (
    <>
      <Seo title="Forgot Password" />
      <div>
        <Card
          elevation={16}
          style={{
            borderRadius: 0,
            padding: "15px 10px 0 10px",
            width: mdUp ? "" : "400px",
          }}
        >
          <CardContent>
            <Box sx={{ mb: 4 }}>
              <Link
                color="text.primary"
                component={RouterLink}
                href={paths.auth.jwt.login}
                sx={{
                  alignItems: "center",
                  display: "inline-flex",
                  marginBottom: "25px",
                }}
                underline="hover"
              >
                <SvgIcon sx={{ mr: 1 }}>
                  <ArrowLeftIcon />
                </SvgIcon>
                <Typography
                  variant="subtitle2"
                  style={{
                    fontSize: 13,
                    color: "black",
                    letterSpacing: 3,
                    fontWeight: 600,
                    userSelect: "none",
                    textTransform: "uppercase",
                    fontFamily: "",
                    marginLeft: "10px",
                  }}
                >
                  Login
                </Typography>
              </Link>
            </Box>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <CustomTextField
                  label="email"
                  name="email"
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                  handleChange={formik.handleChange}
                  value={formik.values.email}
                />
              </Stack>
              {formik.errors.submit && (
                <FormHelperText error sx={{ mt: 3 }}>
                  {formik.errors.submit}
                </FormHelperText>
              )}
              <Box sx={{ padding: "15px 0", mt: 3 }}>
                <CustomButton
                  label="Send reset link"
                  disabled={formik.isSubmitting}
                  type="submit"
                  loginButton
                />
              </Box>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Page;
