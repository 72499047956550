import React, { useState, useEffect, useRef, useCallback } from "react";
import tmwLogo from "src/images/tmw_logo.png";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import {
  Box,
  Container,
  Stack,
  useMediaQuery,
  MenuItem,
  Dialog,
  Button,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // Needed for dayClick
import { CalendarToolbar } from "src/sections/dashboard/calendar/calendar-toolbar";
import Paper from "@mui/material/Paper";
import { alpha, styled } from "@mui/material/styles";
// Icons
import tmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon-black.png";
import redTmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon.png";
import yellowTmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon-yellow.png";
import CloseIcon from "@mui/icons-material/Close";
// Hooks
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/booking-process";
import { slice } from "src/slices/booking-process";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  CustomDivider,
  CustomSelect,
  CustomTooltip,
  CustomUnsavedChangesAlert,
  CustomTextField,
} from "src/components/inputs";
import { CloseOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CalendarContainer = styled("div")(({ theme }) => ({
  "& .fc": {
    "--fc-today-bg-color": alpha(theme.palette.primary.main, 0.2),
  },
  ".fc-scrollgrid": {
    borderStyle: "none !important",
  },
  ".fc td": {
    border: "none",
  },
  ".fc th": {
    borderStyle: "none !important",
    fontWeight: "300",
    fontSize: 14,
  },
  ".fc th .fc-scrollgrid-sync-inner": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc tr": {
    borderBottom: "1px solid #E6E6E6 !important",
    borderRight: "none",
    borderLeft: "none",
  },
  ".fc .fc-highlight": {
    background: "none",
  },
  ".fc .fc-daygrid-day-top": {
    fontFamily: "tmwCustomFont",
    letterSpacing: 3,
    fontSize: 20,
    marginBottom: "-15px",
    display: "flex",
    justifyContent: "center",
  },
  ".fc .fc-daygrid-day.fc-day-today": {
    background: "none",
  },
  ".fc .fc-daygrid-day-events .fc-daygrid-event-harness": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc .fc-h-event": {
    background: "none",
    margin: "17px 0 -17px 0",
    border: "none",
  },
  ".fc .fc-daygrid-day-events .fc-event-main": {
    background: "black",
    borderRadius: "50%",
    maxHeight: "8px",
    width: "8px",
  },
  ".fc .fc-daygrid-day-events .fc-event-main .fc-event-main-frame": {
    visibility: "collapse",
  },
  ".fc-scrollgrid-sync-inner": {
    fontFamily: "tmwCustomFont",
    textAlign: "right",
  },
  ".fc-daygrid-day-frame": {
    paddingTop: "5px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  textAlign: "center",
  color: theme.palette.text.secondary,
  minWidth: "75px",
  padding: "5px 25px",
  userSelect: "none",
  border: "1px solid lightgrey",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px 0",
}));

const BookingAppointment = styled("div")(({ theme }) => ({
  fontFamily: "tmwCustomFont",
  letterSpacing: 3,
  fontSize: 14,
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  textAlign: "center",
  color: "black",
  minWidth: "75px",
  padding: "5px 25px",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px 0",
}));

const BookingItem = styled("div")(() => ({
  fontFamily: "tmwCustomFont",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: 10,
}));

const BookingItemText = styled("div")(({ label }) => ({
  fontFamily: "tmwCustomFont",
  fontSize: 11,
  fontWeight: !!label && 600,
  textTransform: "uppercase",
  textAlign: "left",
}));

export const renderLogo = (mobileView) => {
  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        alignItems: mobileView ? "center" : "",
      }}
    >
      <Stack alignItems="center" direction="row">
        <Box
          component={RouterLink}
          href={paths.index}
          sx={{
            display: "flex",
            height: mobileView ? 125 : 100,
            width: mobileView ? 125 : 100,
          }}
        >
          <img
            style={{
              margin: mobileView ? "0 0 -30px -10px" : "-25% 0 -25% 25px",
              clipPath: "inset(25% 0 25% 0)",
            }}
            src={tmwLogo}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export const renderRefreshAlert = () => {
  return (
    <Container maxWidth={false} style={{ display: "flex" }}>
      <CustomUnsavedChangesAlert />
    </Container>
  );
};

const renderMeetingTypeSelect = (
  meetingType,
  setMeetingType,
  currentBookings,
  currentLocation,
  mdUp,
  meetingTypeValues,
  isBookingModalVisible,
  setIsBookingModalVisible
) => {
  const currentBooking = currentBookings.filter(
    (booking) => booking.location === currentLocation.name
  );

  return (
    <Stack
      id="meeting_type_select"
      style={{
        marginBottom: "25px",
        display: "flex",
        alignItems: "center",
        zIndex: 2000,
      }}
    >
      <Stack style={{ marginBottom: "5px" }}>
        <p
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 3,
            fontSize: 11,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          Meeting type:
        </p>
      </Stack>
      <div>
        <CustomSelect
          width="225px"
          value={currentBooking[0]?.meetingType || meetingType}
          onChange={(e) => setMeetingType(e.target.value)}
          disabled={!!currentBooking[0]?.meetingType}
          style={{ fontSize: 11 }}
        >
          {meetingTypeValues?.map((meetingType) => {
            return (
              <MenuItem key={meetingType} value={meetingType}>
                {meetingType}
              </MenuItem>
            );
          })}
        </CustomSelect>
      </div>
    </Stack>
  );
};

const renderMeetingTypeChangeAlert = (mdUp) => {
  return (
    <Stack
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 15,
        border: "1px solid red",
        padding: "5px 0",
        minWidth: mdUp ? "350px" : "300px",
        maxWidth: mdUp ? "400px" : "340px",
        marginLeft: mdUp && "auto",
        marginRight: mdUp && "auto",
        marginBottom: "25px",
      }}
    >
      <img src={redTmwWarningIcon} style={{ width: "27px" }} />
      <p
        style={{
          width: "225px",
          fontFamily: "tmwCustomFont",
          letterSpacing: 2.5,
          fontSize: 9,
          textTransform: "uppercase",
          textAlign: "center",
          color: "red",
        }}
      >
        The meeting type values have changed
      </p>
    </Stack>
  );
};

const renderNavigateBackButton = (hovered, setHovered, navigate) => {
  return (
    <Button
      onMouseEnter={() => {
        setHovered("back");
      }}
      onMouseLeave={() => {
        setHovered(null);
      }}
      style={{
        fontFamily: "tmwCustomFont",
        borderRadius: "25px",
        letterSpacing: 2,
        fontSize: 8,
        userSelect: "none",
        fontWeight: 400,
        border: "1px solid #E6E6E6",
        color: "black",
        background: hovered === "back" ? "#E6E6E6" : "white",
        textTransform: "uppercase",
      }}
      sx={{
        "&:hover": {
          background: "#E6E6E6",
        },
      }}
      onClick={() => {
        navigate(-1);
      }}
    >
      Back to brand selection
    </Button>
  );
};

const renderConfirmButton = (
  currentBookings,
  handleConfirmBooking,
  bookingError,
  handleCloseErrorClick,
  hoveredNavButton,
  setHoveredNavButton
) => {
  const renderError = () => {
    return (
      !!bookingError && (
        <Stack
          style={{
            width: "500px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            marginTop: "25px",
            marginLeft: "25px",
            border: "1px solid #E6E6E6",
            padding: "0 25px",
          }}
        >
          <p
            style={{
              fontFamily: "tmwCustomFont",
              letterSpacing: 3,
              fontSize: 12,
              textTransform: "uppercase",
            }}
          >
            {bookingError}
          </p>
          <CloseOutlined
            style={{ cursor: "pointer", marginTop: "10px" }}
            onClick={handleCloseErrorClick}
          />
        </Stack>
      )
    );
  };

  return (
    <>
      <Button
        onMouseEnter={() => {
          setHoveredNavButton("confirm");
        }}
        onMouseLeave={() => {
          setHoveredNavButton(null);
        }}
        style={{
          fontFamily: "tmwCustomFont",
          borderRadius: "25px",
          letterSpacing: 2,
          fontSize: 8,
          userSelect: "none",
          fontWeight: 400,
          border: !currentBookings.length
            ? "1px solid #E6E6E6"
            : "1px solid #7CAF00",
          color: !currentBookings.length
            ? "lightgrey"
            : hoveredNavButton !== "confirm" && currentBookings.length
            ? "white"
            : "#7CAF00",
          background:
            hoveredNavButton !== "confirm" &&
            currentBookings.length &&
            "#7CAF00",
          textTransform: "uppercase",
        }}
        sx={{
          "&:hover": {
            background: "white",
          },
        }}
        onClick={handleConfirmBooking}
        disabled={!currentBookings.length}
      >
        Confirm appointments
      </Button>
      {renderError()}
    </>
  );
};

const renderLocationsButton = (
  location,
  locationAppointed,
  currentLocation,
  setCurrentLocation,
  handleUnselect,
  setMeetingType,
  handleCloseErrorClick,
  hoveredLocationButton,
  setHoveredLocationButton,
  index
) => {
  return (
    <Button
      key={location.name}
      onMouseEnter={() => {
        setHoveredLocationButton(index);
      }}
      onMouseLeave={() => {
        setHoveredLocationButton(null);
      }}
      disableRipple
      style={{
        fontFamily: "tmwCustomFont",
        borderRadius: "25px",
        letterSpacing: 2,
        fontSize: 11,
        userSelect: "none",
        fontWeight: 400,
        cursor: currentLocation === location ? "default" : "pointer",
        color:
          locationAppointed && hoveredLocationButton === index
            ? "#7CAF00"
            : locationAppointed && currentLocation !== location
            ? "white"
            : locationAppointed
            ? "#7CAF00"
            : "black",
        background:
          locationAppointed && hoveredLocationButton === index
            ? "white"
            : locationAppointed && currentLocation !== location
            ? "#7CAF00"
            : locationAppointed && currentLocation === location
            ? "white"
            : currentLocation === location && "#E6E6E6",
        border: locationAppointed ? "1px solid #7CAF00" : "1px solid #E6E6E6",
      }}
      sx={{
        "&:hover": {
          background: locationAppointed ? "#7CAF00" : "#E6E6E6",
        },
      }}
      onClick={() => {
        setCurrentLocation(location);
        handleUnselect();
        setMeetingType("Showroom");
        handleCloseErrorClick();
      }}
    >
      {location.name}
    </Button>
  );
};

const renderCurrentLocationBar = (
  currentLocation,
  locations,
  setCurrentLocation,
  setMeetingType,
  handleCloseErrorClick,
  mdUp,
  handleUnselect,
  currentBookings,
  hoveredLocationButton,
  setHoveredLocationButton
) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      marginTop={1}
      marginBottom={1}
    >
      <Stack style={{ marginBottom: "5px" }}>
        <p
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 3,
            fontSize: 11,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          Select one or more locations:
        </p>
      </Stack>
      <Stack
        style={{
          display: "flex",
          flexDirection: mdUp ? "row" : "column",
          gap: 15,
          width: "100%",
          maxWidth: !mdUp && "75vw",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {locations.map((location, index) => {
          const locationAppointed = currentBookings.find((booking) => {
            return booking.location === location.name;
          });
          return renderLocationsButton(
            location,
            locationAppointed,
            currentLocation,
            setCurrentLocation,
            handleUnselect,
            setMeetingType,
            handleCloseErrorClick,
            hoveredLocationButton,
            setHoveredLocationButton,
            index
          );
        })}
      </Stack>
      <div style={{ width: "100px" }} />
    </Stack>
  );
};

const renderBookingTimeBanner = () => {
  return (
    <Item
      style={{
        color: "black",
        padding: "5px",
        marginTop: "25px",
        border: "none",
        boxShadow: "none",
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <img
          src={redTmwWarningIcon}
          style={{ width: "20px", marginRight: "15px" }}
        />
        <span
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 2,
            fontSize: 10,
            textTransform: "uppercase",
            textAlign: "center",
          }}
        >
          Appointments take 60 minutes
        </span>
      </span>
    </Item>
  );
};

const renderCurrentBookings = (currentBookings, setCurrentBookings, mdUp) => {
  const [hoveredAppointment, setHoveredAppointment] = useState(null);

  const renderCloseIcon = (thisLocationBooking, index) => {
    return (
      <CloseIcon
        onMouseEnter={() => {
          setHoveredAppointment(index);
        }}
        onMouseLeave={() => {
          setHoveredAppointment(null);
        }}
        onClick={() => {
          const filteredBookings = currentBookings.filter(
            (booking) => booking.location !== thisLocationBooking.location
          );
          setCurrentBookings(filteredBookings);
          setHoveredAppointment(null);
        }}
        style={{
          position: "absolute",
          top: "5px",
          right: "5px",
          padding: "5px",
          fontSize: !mdUp ? 40 : 30,
          cursor: "pointer",
          color: hoveredAppointment === index || !mdUp ? "red" : "grey",
          marginLeft: "10px",
        }}
      />
    );
  };

  return (
    <Stack
      style={{
        padding: "5px",
        width: !mdUp && "90vw",
        minWidth: "100%",
      }}
    >
      <Stack
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        {currentBookings.length ? (
          <p
            style={{
              fontFamily: "tmwCustomFont",
              letterSpacing: 3,
              fontSize: 11,
              textTransform: "uppercase",
            }}
          >
            Your appointments:
          </p>
        ) : (
          <p
            style={{
              fontFamily: "tmwCustomFont",
              letterSpacing: 3,
              fontSize: 11,
              textTransform: "uppercase",
            }}
          >
            No appointments
          </p>
        )}
      </Stack>
      <Stack
        style={{
          maxHeight: "300px",
          overflow: "auto",
          scrollbarColor: "#E6E6E6 transparent",
          scrollbarWidth: "none",
          borderTop: currentBookings.length !== 0 && "1px solid grey",
          borderBottom: currentBookings.length > 2 && "1px solid grey",
          gap: 10,
        }}
      >
        {currentBookings.map((thisLocationBooking, index) => {
          return (
            <Stack
              key={`current_booking_${index + 1}`}
              style={{
                position: "relative",
                border: "1px solid grey",
                borderTop: index === 0 ? "none" : "1px solid grey",
                borderBottom:
                  index === currentBookings.length - 1 &&
                  currentBookings.length > 2
                    ? "none"
                    : "1px solid grey",
              }}
            >
              <BookingAppointment
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "calc(100% - 40px)",
                }}
              >
                <BookingItem>
                  <BookingItemText label="true">Location:</BookingItemText>
                  <BookingItemText>
                    {thisLocationBooking?.location}
                  </BookingItemText>
                </BookingItem>
                <BookingItem>
                  <BookingItemText label="true">Meeting Type:</BookingItemText>
                  <BookingItemText>
                    {thisLocationBooking?.meetingType}
                  </BookingItemText>
                </BookingItem>
                <BookingItem>
                  <BookingItemText label="true">Date:</BookingItemText>
                  <BookingItemText>
                    {moment(thisLocationBooking?.date).format("DD/MM/yyyy")}
                  </BookingItemText>
                </BookingItem>
                <BookingItem>
                  <BookingItemText label="true">Time:</BookingItemText>
                  <BookingItemText>
                    {thisLocationBooking?.slot}
                    {thisLocationBooking.timezoneOffset &&
                      ` (GMT${
                        thisLocationBooking.timezoneOffset >= 0
                          ? `+${thisLocationBooking.timezoneOffset}`
                          : `-${thisLocationBooking.timezoneOffset * -1}`
                      })`}
                  </BookingItemText>
                </BookingItem>
                <BookingItem>
                  <BookingItemText label="true">Brands:</BookingItemText>
                  <BookingItemText>
                    {thisLocationBooking?.brands
                      ?.map((brand) => brand.brand_name)
                      ?.join(", ")}
                  </BookingItemText>
                </BookingItem>
              </BookingAppointment>
              {renderCloseIcon(thisLocationBooking, index)}
            </Stack>
          );
        })}
      </Stack>
      {currentBookings.length ? renderBookingTimeBanner() : null}
    </Stack>
  );
};

const renderNotesField = (mdUp) => {
  const dispatch = useDispatch();

  const [notes, setNotes] = useState("");

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      dispatch(slice.actions.setNotes(notes));
    }, 300);
    return () => clearTimeout(delayInputTimeoutId);
  }, [notes, 300]);

  return (
    <Stack
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: mdUp && "25px",
        width: !mdUp ? "85vw" : "110%",
        margin: "30px 0 30px 0",
      }}
    >
      <NotesField
        value={notes}
        handleChange={(e) => setNotes(e.target.value)}
        style={{ width: mdUp ? "90%" : "100%" }}
      />
    </Stack>
  );
};

const renderOptions = (
  mdUp,
  currentLocation,
  currentBookings,
  setCurrentBookings,
  showMeetingTypeChangeAlert,
  setShowMeetingTypeChangeAlert
) => {
  return (
    <Stack
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: mdUp ? "0 25px" : "0 25px",
        width: mdUp ? "25vw" : "80vw",
        margin: mdUp ? "0 25px" : "-25px",
      }}
    >
      {renderCurrentBookings(currentBookings, setCurrentBookings, mdUp)}
      {renderNotesField(mdUp)}
      {showMeetingTypeChangeAlert && renderMeetingTypeChangeAlert(mdUp)}
    </Stack>
  );
};

const renderAvailableTimesList = (
  mdUp,
  visible,
  currentSelectedDate,
  setCurrentSelectedDate,
  setIsBookingModalVisible,
  handleUnselect,
  currentBrands,
  location,
  currentBookings,
  setCurrentBookings,
  calendarRef,
  meetingType,
  setShowMeetingTypeChangeAlert
) => {
  const dispatch = useDispatch();
  const params = useParams();
  const userId = params?.id;

  const { timeSlots, timezoneOffset } = useSelector(
    (state) => state.bookingProcess
  );
  const [availableTimes, setAvailableTimes] = useState([]);
  const [hoverTime, setHoverTime] = useState("");

  const timeSlotInfo = (timeSlot) => {
    if (timeSlot.availableSlots > 2) {
      return {
        enabled: true,
        background: "#ddffdd",
        border: "2px solid green",
        color: "black",
        tooltip: false,
        tooltipInfo: "",
      };
    } else if (timeSlot.availableSlots === 0) {
      return {
        enabled: false,
        background: "#FF9C89",
        border: "2px solid #bf6c49",
        color: "black",
        tooltip: true,
        tooltipInfo: "Not available",
      };
    }
    return {
      enabled: true,
      background: "#ffec9b",
      border: "2px solid #cfbc6b",
      color: "black",
      tooltip: true,
      tooltipInfo: "Limited availability, book fast!",
    };
  };

  const onSlotClick = (timeSlot, calendarRef) => {
    // Disable onclick if the slot is fully booked
    if (!timeSlotInfo(timeSlot).enabled) return;

    setCurrentBookings([
      ...currentBookings,
      {
        brands: currentBrands,
        location: location.name,
        date: currentSelectedDate,
        slot: timeSlot.time,
        meetingType,
        timezoneOffset,
      },
    ]);

    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.getEventById("selectedDate")?.remove();
    }
    setIsBookingModalVisible(false);
    setAvailableTimes([]);
    setCurrentSelectedDate("");
    setShowMeetingTypeChangeAlert(false);
  };

  const displayTimezoneOffset = () => {
    if (timezoneOffset !== null && timezoneOffset !== undefined) {
      return ` (GMT${
        timezoneOffset >= 0 ? `+${timezoneOffset}` : `-${timezoneOffset * -1}`
      })`;
    }
  };

  const getTimezoneData = (availableTimes) => {
    const userTimezoneOffset = new Date().getTimezoneOffset() / 60;

    return availableTimes.map((availableTime) => {
      const gmtTime = moment(availableTime.time, "HH:mm")
        .add(userTimezoneOffset, "h")
        .format("HH:mm");
      return { userTime: gmtTime, ...availableTime };
    });
  };

  const renderList = (calendarRef, mdUp) => {
    const locationOffset = timezoneOffset - moment().utcOffset() / 60;

    return (
      <Stack
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {!mdUp && (
          <Stack
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "10px 0",
              margin: "20px 0 10px 0",
            }}
          >
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
                fontFamily: "tmwCustomFont",
                letterSpacing: 3,
                fontSize: 11,
                textTransform: "uppercase",
                padding: "5px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: "5px",
                }}
              >
                Selected date:
              </span>{" "}
              {currentSelectedDate}
            </Stack>
            <Stack
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "space-between",
                fontFamily: "tmwCustomFont",
                letterSpacing: 3,
                fontSize: 11,
                textTransform: "uppercase",
                padding: "5px",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  marginRight: "5px",
                }}
              >
                Meeting type:
              </span>{" "}
              {meetingType}
            </Stack>
          </Stack>
        )}
        {!mdUp && (
          <CloseIcon
            style={{
              position: "absolute",
              top: 6,
              right: 6,
              fontSize: 30,
              color: "#BEBEBE",
            }}
            onClick={() => handleUnselect()}
          />
        )}
        <Stack
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontFamily: "tmwCustomFont",
              letterSpacing: 3,
              fontSize: 14,
              textTransform: "uppercase",
              marginLeft: !mdUp ? "25px" : "",
              marginRight: !mdUp ? "25px" : "",
            }}
          >
            <span style={{ fontWeight: 600 }}>Time</span>
            <span style={{ marginLeft: "5px" }}>{displayTimezoneOffset()}</span>
          </p>
        </Stack>
        <Stack
          style={{ width: "100%", background: "#E6E6E6", height: "1px" }}
        />
        <Stack
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "35vh",
            width: mdUp ? "20vw" : "100%",
            padding: "0 25px",
            overflowY: "scroll",
            zIndex: 8,
          }}
        >
          <Stack
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            {getTimezoneData(availableTimes).map((timeSlot, index) => {
              if (
                (currentSelectedDate ===
                  moment().add(locationOffset, "hours").format("yyyy-MM-DD") &&
                  moment().add(locationOffset, "hours").format("HH:mm") <
                    timeSlot.time) ||
                currentSelectedDate !==
                  moment().add(locationOffset, "hours").format("yyyy-MM-DD")
              )
                return (
                  <Stack
                    onClick={() => {
                      onSlotClick(timeSlot, calendarRef);
                    }}
                    onMouseEnter={() => setHoverTime(timeSlot.time)}
                    onMouseLeave={() => setHoverTime("")}
                    style={{
                      width: "75%",
                      transition: "all 0.15s ease-in-out",
                      position: "relative",
                      cursor: timeSlotInfo(timeSlot).enabled ? "pointer" : "",
                      userSelect: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: timeSlotInfo(timeSlot).enabled
                        ? "black"
                        : "#E6E6E6",
                      border: "1px solid #E6E6E6",
                      borderTop: index === 0 && "none",
                      borderLeft: "none",
                      borderRight: "none",
                    }}
                    key={timeSlot.time}
                    id="booking_time_selection"
                  >
                    <span
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "tmwCustomFont",
                        letterSpacing: 3,
                        fontSize: 18,
                        textTransform: "uppercase",
                        gap: "25px",
                        marginTop: "-10px",
                        width: "100%",
                      }}
                      id="booking_time_selection_span"
                    >
                      <p id="booking_time_selection_p">{timeSlot.time}</p>
                      {timeSlotInfo(timeSlot).tooltip && (
                        <CustomTooltip
                          title={timeSlotInfo(timeSlot).tooltipInfo}
                        >
                          <img
                            id="booking_tooltip_img"
                            src={
                              timeSlotInfo(timeSlot).tooltipInfo ===
                              "Not available"
                                ? redTmwWarningIcon
                                : yellowTmwWarningIcon
                            }
                            style={{
                              width: "20px",
                              position: "absolute",
                              left: "75%",
                            }}
                          />
                        </CustomTooltip>
                      )}
                    </span>
                  </Stack>
                );
            })}
          </Stack>
        </Stack>
      </Stack>
    );
  };

  // Fetch times from bookings when the selected date changes
  useEffect(() => {
    if (visible === true) {
      dispatch(
        thunks.getTimeSlots(userId, {
          brands: currentBrands.map((brand) => brand.brand_name),
          location: location.name,
          date: currentSelectedDate,
        })
      );
    }
  }, [currentSelectedDate]);

  useEffect(() => {
    if (location && currentSelectedDate) {
      dispatch(
        thunks.getTimezoneOffset(userId, location.name, currentSelectedDate)
      );
    }
  }, [currentSelectedDate]);

  useEffect(() => {
    if (timeSlots?.length) {
      setAvailableTimes(timeSlots);
    }
  }, [timeSlots]);

  if (visible) {
    return mdUp ? (
      renderList(calendarRef, mdUp)
    ) : (
      <Dialog
        open={visible}
        onClose={handleUnselect}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        PaperProps={{
          sx: {
            borderRadius: 0,
            border: "1px solid #BEBEBE",
          },
        }}
      >
        <Stack style={{ width: "75vw" }}>{renderList(calendarRef)}</Stack>
      </Dialog>
    );
  }
};

const renderBrandSelectionSection = (currentBrands, mobileView, mdUp) => {
  return (
    <Stack
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: !mdUp ? "10px 0" : "25px 0",
      }}
    >
      <div
        style={{
          fontFamily: "tmwCustomFont",
          letterSpacing: 3,
          fontSize: 11,
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        Brands available in the selected location:
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: mdUp ? "row" : "column",
          fontFamily: "tmwCustomFont",
          letterSpacing: 4,
          fontSize: !mdUp ? 14 : 16,
          fontWeight: 500,
          textTransform: "uppercase",
          width: mobileView ? "90%" : "800px",
          wordBreak: "normal",
          lineHeight: "50px",
          marginTop: "5px",
        }}
      >
        {currentBrands.map((brand, index) => {
          if (index !== currentBrands.length - 1) {
            return `${brand.brand_name}, `;
          }
          return brand.brand_name;
        })}
      </div>
    </Stack>
  );
};

export const CalendarComponent = ({
  nrOfLocations,
  location,
  handleNextLocation,
  handlePrevLocation,
  currentBrands,
  currentBookings,
  setCurrentBookings,
  meetingType,
  setMeetingType,
  handleConfirmBooking,
  locations,
  setCurrentLocation,
  meetingTypeValues,
  setMeetingTypeValues,
  showMeetingTypeChangeAlert,
  setShowMeetingTypeChangeAlert,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const userId = params?.id;
  const dispatch = useDispatch();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mobileView = !mdUp;
  const [date, setDate] = useState(new Date());
  const [currentSelectedDate, setCurrentSelectedDate] = useState("");
  const [isBookingModalVisible, setIsBookingModalVisible] = useState(false);
  const [hoveredNavButton, setHoveredNavButton] = useState(null);
  const [hoveredLocationButton, setHoveredLocationButton] = useState(null);

  const { bookingError, allTimezones } = useSelector(
    (state) => state.bookingProcess
  );

  useEffect(() => {
    dispatch(thunks.getAllTimezones(userId));
  }, []);

  // Get the timezone differnece of location relative to the client location
  const locationOffset = allTimezones[location.name];
  const timezoneOffset = locationOffset - moment().utcOffset() / 60;

  const calendarRef = useRef(null);

  const handleDatePrev = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDateNext = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  }, []);

  const unselectCurrentDay = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.unselect();
    }
  };

  const handleOpenTimeSelect = (e) => {
    if (e?.dayEl?.classList?.value.includes("fc-day-other")) {
      return;
    }

    const availabilityDates = [];
    location?.availability.map((date) => {
      availabilityDates.push(date.day);
    });

    // If the selected date falls outside of the array don't allow it to be selected
    if (!availabilityDates.includes(e.dateStr)) {
      handleUnselect();
      setIsBookingModalVisible(false);
      return;
    }

    // Check if the user has already made a booking for this location and if yes don't allow him to book
    const isBooked = currentBookings.filter(
      (booking) => booking.location === location.name
    ).length;
    if (isBooked) return;

    const selectedDayInfo = location?.availability.find((date) => {
      return date.day === e.dateStr;
    });

    if (selectedDayInfo) {
      if (
        JSON.stringify(selectedDayInfo.meetingTypes) !==
        JSON.stringify(meetingTypeValues)
      ) {
        setShowMeetingTypeChangeAlert(true);
      } else {
        setShowMeetingTypeChangeAlert(false);
      }
      setMeetingTypeValues(selectedDayInfo.meetingTypes);
    }
    setCurrentSelectedDate(e.dateStr);
    setIsBookingModalVisible(true);
  };

  const handleUnselect = (e) => {
    if (
      e?.jsEvent?.target?.id !== "booking_time_selection" &&
      e?.jsEvent?.target?.id !== "booking_time_selection_span" &&
      e?.jsEvent?.target?.id !== "booking_time_selection_p" &&
      e?.jsEvent?.target?.id !== "booking_tooltip_img" &&
      e?.jsEvent?.target?.id !== "meeting_type_select"
    ) {
      const calendarEl = calendarRef.current;
      if (calendarEl) {
        const calendarApi = calendarEl.getApi();
        calendarApi.getEventById("selectedDate")?.remove();
      }
      setCurrentSelectedDate("");
      setIsBookingModalVisible(false);
      unselectCurrentDay();
    }
  };

  // Close modal on esc key press
  const handleEscKey = useCallback(
    (event) => {
      setCurrentSelectedDate("");
      setIsBookingModalVisible(false);
      unselectCurrentDay();
    },
    [setIsBookingModalVisible]
  );

  const handleCloseErrorClick = () => {
    dispatch(slice.actions.setBookingError(""));
  };

  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);

    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl && currentSelectedDate) {
      const calendarApi = calendarEl.getApi();

      calendarApi.getEventById("selectedDate")?.remove();

      calendarApi.addEvent({
        id: "selectedDate",
        start: currentSelectedDate,
      });
    }
  }, [currentSelectedDate]);

  return (
    <Stack width="100%">
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          width: "100%",
          marginTop: "15px",
        }}
      >
        <span
          style={{
            width: "100%",
            marginTop: mobileView ? "-50px" : "",
            marginBottom: mobileView ? "-15px" : "",
          }}
        >
          {renderLogo(mobileView)}
        </span>
        {mdUp && (
          <Stack
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {renderCurrentLocationBar(
              location,
              locations,
              setCurrentLocation,
              setMeetingType,
              handleCloseErrorClick,
              mdUp,
              handleUnselect,
              currentBookings,
              hoveredLocationButton,
              setHoveredLocationButton
            )}
          </Stack>
        )}
      </span>
      <Container maxWidth="xxl">
        {!mdUp && (
          <Stack>
            {renderCurrentLocationBar(
              location,
              locations,
              setCurrentLocation,
              setMeetingType,
              handleCloseErrorClick,
              mdUp,
              handleUnselect,
              currentBookings,
              hoveredLocationButton,
              setHoveredLocationButton
            )}
          </Stack>
        )}
        {renderBrandSelectionSection(currentBrands, mobileView, mdUp)}
        <CustomDivider fullWidth />
        {!mdUp && (
          <div style={{ marginTop: "15px" }}>
            {renderMeetingTypeSelect(
              meetingType,
              setMeetingType,
              currentBookings,
              location,
              mdUp,
              meetingTypeValues,
              isBookingModalVisible,
              setIsBookingModalVisible
            )}
          </div>
        )}
        <Stack
          style={{
            display: "flex",
            flexDirection: mdUp ? "row" : "column",
            alignItems: mdUp ? "flex-start" : "center",
            justifyContent: mdUp ? "flex-start" : "center",
            marginTop: !mdUp ? "25px" : "15px",
          }}
        >
          {mdUp &&
            renderOptions(
              mdUp,
              location,
              currentBookings,
              setCurrentBookings,
              showMeetingTypeChangeAlert,
              setShowMeetingTypeChangeAlert
            )}
          <Stack
            style={{
              width: mdUp ? "40vw" : "100%",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CalendarToolbar
              startingDate={new Date(location?.availability[0].day)}
              date={date}
              onDateNext={handleDateNext}
              onDatePrev={handleDatePrev}
              location={location}
              nrOfLocations={nrOfLocations}
              handleNextLocation={handleNextLocation}
              handlePrevLocation={handlePrevLocation}
            />
            <CalendarContainer
              sx={{
                width: mobileView ? "350px" : "500px",
                // Set dates outside the month bound same color as the ones inside the month
                [moment(location?.availability[0].day).isAfter(
                  moment(moment().add(timezoneOffset, "hours")).format(
                    "yyyy-MM-DD"
                  )
                ) &&
                ".fc td.fc-day.fc-day-today  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner"]:
                  {
                    opacity: "20%",
                  },
                [moment(location?.availability[0].day) ===
                  moment().add(timezoneOffset, "hours").format("yyyy-MM-DD") &&
                ".fc td.fc-day.fc-day-today"]: { opacity: "20%" },
                ".fc td.fc-day.fc-day-past.fc-day-other": {
                  opacity: "0%",
                  cursor: "default !important",
                },
                ".fc td.fc-day.fc-day-past  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top":
                  {
                    opacity: "20%",
                  },
                ".fc td.fc-day.fc-day-future.fc-day-other": {
                  opacity: "0%",
                  cursor: "default !important",
                },
                ".fc td.fc-day.fc-day-future  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top":
                  {
                    opacity: "20%",
                  },
                ...Object.assign(
                  {},
                  ...location.availability.map((date) => ({
                    [`td[data-date="${date.day}"] .fc-daygrid-day-frame.fc-scrollgrid-sync-inner`]:
                      {
                        opacity: "100% !important",
                        cursor: "pointer",
                      },
                    [`td[data-date="${date.day}"] .fc-daygrid-day-frame.fc-scrollgrid-sync-inner  .fc-daygrid-day-top`]:
                      {
                        opacity: "100% !important",
                      },
                    [`td[data-date="${date.day}"].fc-day-other .fc-daygrid-day-frame.fc-scrollgrid-sync-inner`]:
                      {
                        opacity: "0% !important",
                        cursor: "default !important",
                      },
                  }))
                ),
              }}
            >
              <FullCalendar
                dayHeaderFormat={{ weekday: "narrow" }}
                allDayMaintainDuration
                dayMaxEventRows={3}
                eventDisplay="block"
                eventResizableFromStart
                headerToolbar={false}
                height={mobileView ? 450 : 450}
                aspectRatio={0.75}
                firstDay={1}
                initialDate={moment(location?.availability[0].day)
                  .add(timezoneOffset, "hours")
                  .format("yyyy-MM-DD")}
                selectConstraint={{
                  start: moment(moment().add(timezoneOffset, "hours")).isAfter(
                    moment(location?.availability[0].day).add(
                      timezoneOffset,
                      "hours"
                    )
                  )
                    ? moment(moment().add(timezoneOffset, "hours")).subtract(
                        1,
                        "day"
                      )
                    : moment(location?.availability[0].day).subtract(1, "day"),
                  end: moment(location?.availability.slice(-1)[0].day).add(
                    1,
                    "day"
                  ),
                }}
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  timeGridPlugin,
                ]}
                ref={calendarRef}
                rerenderDelay={10}
                weekends
                selectable
                unselect={handleUnselect}
                dateClick={handleOpenTimeSelect}
              />
            </CalendarContainer>
            {mdUp && (
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 15,
                }}
              >
                {renderNavigateBackButton(
                  hoveredNavButton,
                  setHoveredNavButton,
                  navigate
                )}
                {renderConfirmButton(
                  currentBookings,
                  handleConfirmBooking,
                  bookingError,
                  handleCloseErrorClick,
                  hoveredNavButton,
                  setHoveredNavButton
                )}
              </Stack>
            )}
          </Stack>
          {!mdUp &&
            renderOptions(
              mdUp,
              location,
              currentBookings,
              setCurrentBookings,
              showMeetingTypeChangeAlert,
              setShowMeetingTypeChangeAlert
            )}
          <Stack
            style={{
              width: "25%",
              position: "sticky",
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
            }}
          >
            {mdUp &&
              renderMeetingTypeSelect(
                meetingType,
                setMeetingType,
                currentBookings,
                location,
                mdUp,
                meetingTypeValues,
                isBookingModalVisible,
                setIsBookingModalVisible
              )}
            {renderAvailableTimesList(
              mdUp,
              isBookingModalVisible,
              currentSelectedDate,
              setCurrentSelectedDate,
              setIsBookingModalVisible,
              handleUnselect,
              currentBrands,
              location,
              currentBookings,
              setCurrentBookings,
              calendarRef,
              meetingType,
              setShowMeetingTypeChangeAlert
            )}
          </Stack>
        </Stack>
        {!mdUp && (
          <Stack
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: mdUp && "50px",
              marginTop: !mdUp && "50px",
            }}
          >
            <Stack
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-25px",
                gap: 25,
              }}
            >
              <Stack
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 15,
                  width: !mdUp && "90vw",
                  marginBottom: !mdUp && "25px",
                }}
              >
                {renderNavigateBackButton(
                  hoveredNavButton,
                  setHoveredNavButton,
                  navigate
                )}
                {renderConfirmButton(
                  currentBookings,
                  handleConfirmBooking,
                  bookingError,
                  handleCloseErrorClick,
                  hoveredNavButton,
                  setHoveredNavButton
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Container>
    </Stack>
  );
};

const NotesField = ({ value, handleChange, style }) => {
  return (
    <CustomTextField
      label="Notes"
      name="Notes"
      value={value}
      handleChange={handleChange}
      style={style}
      textFieldStyle={{
        padding: "6px 0",
      }}
      multiline
      greyBar
    />
  );
};
