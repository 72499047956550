import { useEffect, useState, useRef, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Container,
  Popper,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useAuth } from "src/hooks/use-auth";
import { withBookingSystemGuard } from "src/hocs/with-role-based-guard";
import Error404Page from "src/pages/404";
import { useTranslation } from "react-i18next";
// User Group Types
import {
  bookingSystemTomorrowCrewPermissions,
  groupTypes,
} from "src/guards/role-based-guard";
// Redux Toolkit
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/booking-system";
import { slice } from "src/slices/booking-system";
import {
  CustomButton,
  CustomTextField,
  CustomTooltip,
  CustomTypography,
} from "src/components/inputs";
import moment from "moment";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from "date-fns/locale";
import { alpha, styled } from "@mui/material/styles";
// Calendar
import { CalendarToolbar } from "src/sections/dashboard/calendar/calendar-toolbar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // Needed for dayClick
import {
  ArrowDropDown,
  CloseOutlined,
  ViewList as ViewListIcon,
} from "@mui/icons-material";
import ReactDOMServer from "react-dom/server";
import { format } from "date-fns";
import tmwOnlineMeeting from "src/icons/tmw-ui/tmw-online-meeting.png";
import tmwShop from "src/icons/tmw-ui/tmw-shop.png";
import tmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon.png";
import EventIcon from "@mui/icons-material/Event";
import { Seo } from "src/components/seo";

const CalendarContainer = styled("div")(({ theme }) => ({
  width: "40%",
  height: "90%",
  "& .fc": {
    "--fc-today-bg-color": alpha(theme.palette.primary.main, 0.2),
    userSelect: "none",
  },
  ".fc-scrollgrid": {
    borderStyle: "none !important",
  },
  ".fc td": {
    border: "none",
  },
  ".fc th": {
    borderStyle: "none !important",
    fontWeight: "300",
    fontSize: 14,
  },
  ".fc th .fc-scrollgrid-sync-inner": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc tr": {
    borderBottom: "1px solid #E6E6E6 !important",
    borderRight: "none",
    borderLeft: "none",
  },
  ".fc .fc-highlight": {
    background: "none",
  },
  ".fc .fc-daygrid-day-top": {
    fontFamily: "tmwCustomFont",
    letterSpacing: 3,
    fontSize: 20,
    marginBottom: "-15px",
    display: "flex",
    justifyContent: "center",
  },
  ".fc .fc-daygrid-day.fc-day-today": {
    background: "white",
    // border: "1px solid black",
    // opacity: "100%",
  },
  ".fc .fc-daygrid-day-events .fc-daygrid-event-harness": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc-daygrid-day-number": {
    paddingLeft: "7px",
  },
  ".fc .fc-h-event": {
    background: "none",
    marginTop: "8px",
    border: "none",
  },
  ".fc .fc-daygrid-day-events .fc-event-main": {
    background: "black",
    borderRadius: "50%",
    height: "6px",
    width: "6px",
  },
  ".fc .fc-daygrid-day-events .fc-event-main .fc-event-main-frame": {
    // visibility: "collapse",
  },
  ".fc-scrollgrid-sync-inner": {
    fontFamily: "tmwCustomFont",
    textAlign: "right",
  },
  ".fc-daygrid-day-frame": {
    paddingTop: "5px",
  },
  ".fc-media-screen": {
    minHeight: "85%",
    minWidth: "85%",
  },
  ".fc-daygrid-day-frame.fc-scrollgrid-sync-inner": {
    opacity: "100% !important",
  },
  ".fc-daygrid-day-frame.fc-scrollgrid-sync-inner  .fc-daygrid-day-top": {
    opacity: "100% !important",
  },
  ".fc-day-other .fc-daygrid-day-frame.fc-scrollgrid-sync-inner": {
    opacity: "0% !important",
    cursor: "default !important",
  },
  ".fc-day-other": {
    background: "white !important",
    color: "white !important",
    userSelect: "none !important",
    border: "none !important", // Overwrite the border of the today date outside the month
  },
}));

const CalendarViewPage = ({
  filters,
  setFilters,
  handleFilterChange,
  renderResetFiltersButton,
  pageView,
  setPageView,
  allBookings,
  allBookingsLoading,
  newClientDropdownValues,
  newClientDropdownValuesLoading,
  salesCampaignDropdownValues,
  allLocations,
  exportLoading,
}) => {
  // Redundant permission check
  const { user } = useAuth();
  const userPermissions = user?.permissions;

  const hasAccess = userPermissions?.find(
    (object) =>
      object.key === bookingSystemTomorrowCrewPermissions.admin ||
      object.key === bookingSystemTomorrowCrewPermissions.bookingSystemAdmin ||
      object.key === bookingSystemTomorrowCrewPermissions.bookingSystemUser
  );

  if (!hasAccess) {
    return <Error404Page />;
  }

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mobileView = !mdUp;
  const dispatch = useDispatch();

  const calendarViewTypes = {
    month: "Month",
    week: "Week",
  };

  const [date, setDate] = useState(new Date());
  const [savedDate, setSavedDate] = useState(new Date());
  const [renderedEvents, setRenderedEvents] = useState([]);

  const [calendarType, setCalendarType] = useState(calendarViewTypes.month);
  const [currentWeekTitle, setCurrentWeekTitle] = useState("");
  const [switchedMonth, setSwitchedMonth] = useState(false);

  // Booking states
  const [bookingAppointmentId, setBookingAppointmentId] = useState(null);
  const [bookingDate, setBookingDate] = useState(null);
  const [bookingTime, setBookingTime] = useState(null);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [bookingLocation, setBookingLocation] = useState(null);
  const [bookingMeetingType, setBookingMeetingType] = useState(null);
  const [hoveredAppointmentIndex, setHoveredAppointmentIndex] = useState(null);
  const [openAmendBookingModal, setOpenAmendBookingModal] = useState(false);
  const [openDeleteBookingModal, setOpenDeleteBookingModal] = useState(false);
  const [hoveredDeleteBookingButton, setHoveredDeleteBookingButton] =
    useState(false);
  const [loadingWeek, setLoadingWeek] = useState(false);

  // User Group
  const userGroup = user.group?.key;
  const userId = user.hashedId;

  const renderQuickSearch = () => {
    return (
      <Stack
        style={{
          flexDirection: "row",
          width: "200px",
        }}
      >
        <Stack
          id="custom-searchbar"
          sx={{
            "& .MuiInput-root:before": {
              borderBottom: "none !important",
            },
            "& .MuiInput-root:after": {
              borderBottom: "none !important",
            },
            "& .MuiInput-underline:before": {
              borderBottom: "none !important",
            },
            "& .MuiSvgIcon-root": {
              cursor: "pointer",
            },
            "& .MuiInputBase-root-MuiInput-root": {
              cursor: "pointer",
            },
            "& .MuiInput-input": {
              fontFamily: "tmwCustomFont",
              paddingBottom: "2px",
              fontSize: 11,
            },
            "& .MuiInput-input::placeholder": {
              color: "#BEBEBE",
            },
            "& .MuiInput-input::-ms-input-placeholder": {
              color: "black",
            },
            overflow: "hidden",
          }}
          style={{
            fontFamily: "tmwCustomFont",
            borderRadius: "25px",
            border: "1px solid #E6E6E6",
            padding: "5px 10px",
            letterSpacing: 2,
            width: "200px",
            cursor: "pointer",
            transition: "all .2s ease-in-out",
          }}
          size="small"
          placeholder={"SEARCH"}
        />
      </Stack>
    );
  };

  const renderListViewButton = () => {
    return (
      <CustomButton
        label={
          <>
            <ViewListIcon style={{ marginRight: 15, fontSize: 22 }} />
            List view
          </>
        }
        style={{ width: "200px" }}
        onClick={() => {
          setPageView("List");
        }}
      />
    );
  };

  const renderCalendarTypeButton = () => {
    return calendarType === calendarViewTypes.week ? (
      <CustomButton
        label="Monthly View"
        style={{ width: "200px" }}
        onClick={() => {
          setCalendarType(calendarViewTypes.month);
        }}
      />
    ) : (
      <CustomButton
        label="Weekly View"
        style={{ width: "200px" }}
        onClick={() => {
          setCalendarType(calendarViewTypes.week);
        }}
      />
    );
  };

  const renderCircularProgress = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1100,
          color: "black",
        }}
      >
        <CircularProgress color="inherit" size={35} />
      </Box>
    );
  };

  const renderExportButton = () => {
    const [hoveredButton, setHoveredButton] = useState(false);
    const disabled = exportLoading || filters.allBookingsRows.length === 0;

    return (
      <Button
        onMouseEnter={() => setHoveredButton(true)}
        onMouseLeave={() => setHoveredButton(false)}
        style={{
          fontFamily: "tmwCustomFont",
          textTransform: "uppercase",
          borderRadius: "25px",
          border: !disabled ? "1px solid green" : "1px solid #E6E6E6",
          transition: "ease-out 0.15s",
          letterSpacing: 2,
          fontSize: 11,
          userSelect: "none",
          fontWeight: 400,
          color: !disabled ? (hoveredButton ? "white" : "green") : "#BEBEBE",
          background: hoveredButton ? "green" : "white",
        }}
        onClick={() => {
          dispatch(thunks.exportBookingsToICS(userId, filters));
        }}
        disabled={disabled}
        disableTouchRipple
      >
        Export
        <EventIcon
          style={{
            margin: "0 3px 1px 5px",
            fontSize: 16,
          }}
        />
        {exportLoading && (
          <CircularProgress
            size={20}
            color="inherit"
            style={{ marginLeft: "5px" }}
          />
        )}
      </Button>
    );
  };

  //
  // Components
  //

  const renderBrandAutoselect = () => {
    const brandAutocompleteData = [
      ...new Set(
        allBookings?.reduce((acc, label) => {
          label.brands.map((brand) => {
            acc.push(brand);
          });
          return acc;
        }, [])
      ),
    ]
      .filter(Boolean)
      .sort();

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Brand
        </span>
        <Autocomplete
          id="autocomplete-brand"
          name="autocomplete-brand"
          options={brandAutocompleteData}
          value={filters.selectedBrand}
          onChange={(e, value) => {
            handleFilterChange("selectedBrand", value);
            reloadWeekCalendar();
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "150px",
                }}
                id="selectedBrand"
                name="selectedBrand"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderCategoryAutoselect = () => {
    const categoryAutocompleteData = [
      ...new Set(allBookings?.flatMap((booking) => booking.categories)),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Category
        </span>
        <Autocomplete
          id="autocomplete-category"
          name="autocomplete-category"
          options={categoryAutocompleteData}
          value={filters.selectedCategory}
          onChange={(e, value) => {
            handleFilterChange("selectedCategory", value);
            reloadWeekCalendar();
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "150px",
                }}
                id="selectedCategory"
                name="selectedCategory"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderSeasonAutoselect = () => {
    const seasonAutocompleteData = [
      ...new Set(allBookings?.map((booking) => booking.season)),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Season
        </span>
        <Autocomplete
          id="autocomplete-season"
          name="autocomplete-season"
          options={seasonAutocompleteData}
          value={filters.selectedSeason}
          onChange={(e, value) => {
            handleFilterChange("selectedSeason", value);
            reloadWeekCalendar();
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  minWidth: "150px",
                }}
                id="selectedSeason"
                name="selectedSeason"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderLocationAutoselect = () => {
    const locationAutocompleteData = [
      ...new Set(allBookings?.map((booking) => booking.location)),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Location
        </span>
        <Autocomplete
          id="autocomplete-location"
          name="autocomplete-location"
          options={locationAutocompleteData}
          value={filters.selectedLocation}
          onChange={(e, value) => {
            handleFilterChange("selectedLocation", value);
            reloadWeekCalendar();
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                    zIndex: 1000,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  "& .MuiButtonBase-root, MuiChip-root, MuiChip-filled": {
                    background: "white",
                    height: "20px",
                    borderRadius: 0,
                    fontFamily: "tmwCustomFont",
                  },
                  padding: "10px 0",
                  minWidth: "175px",
                }}
                id="selectedLocation"
                name="selectedLocation"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderFromDateAutoselect = () => {
    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          From Date
        </span>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          onChange={(date) => {
            handleFilterChange("fromDate", date);
          }}
          renderInput={(inputProps) => (
            <>
              <CustomTextField
                style={{ width: "150px" }}
                // label={t(tokens.linesheets.table.windowStart)}w
                // onKeyDown={onKeyDown}
                inputProps={inputProps}
                calendar
                clearable={filters.fromDate}
                clearableClick={() => {
                  handleFilterChange("fromDate", null);
                  reloadWeekCalendar();
                }}
                // touched={formik.touched.inboundDelivery?.from}
                // errors={formik.errors.inboundDelivery?.from}
              />
            </>
          )}
          value={filters.fromDate}
          // maxDate={formik.values.inboundDelivery.to}
        />
      </Stack>
    );
  };

  const renderToDateAutoselect = () => {
    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px -25px -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          To Date
        </span>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          onChange={(date) => {
            handleFilterChange("toDate", date);
          }}
          renderInput={(inputProps) => (
            <>
              <CustomTextField
                style={{ width: "150px" }}
                // label={t(tokens.linesheets.table.windowStart)}w
                // onKeyDown={onKeyDown}
                inputProps={inputProps}
                calendar
                clearable={filters.toDate}
                clearableClick={() => {
                  handleFilterChange("toDate", null);
                  reloadWeekCalendar();
                }}
                // touched={formik.touched.inboundDelivery?.from}
                // errors={formik.errors.inboundDelivery?.from}
              />
            </>
          )}
          value={filters.toDate}
          // maxDate={formik.values.inboundDelivery.to}
        />
      </Stack>
    );
  };

  const renderMeetingTypeAutoselect = () => {
    const meetingTypeAutocompleteData = [
      ...new Set(allBookings?.map((booking) => booking.meetingType)),
    ].filter(Boolean);

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Meeting Type
        </span>
        <Autocomplete
          id="autocomplete-meetingType"
          name="autocomplete-meetingType"
          options={meetingTypeAutocompleteData}
          value={filters.selectedMeetingType}
          onChange={(e, value) => {
            handleFilterChange("selectedMeetingType", value);
            reloadWeekCalendar();
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "150px",
                }}
                id="selectedMeetingType"
                name="selectedMeetingType"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderSyncedWithHubspotAutoselect = () => {
    const syncedWithHubspotAutocompleteData = ["Yes", "No"];

    return (
      <Stack
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "30px 0 -15px 0",
        }}
      >
        <span
          style={{
            fontFamily: "tmwCustomFont",
            fontSize: 11,
            color: "black",
            letterSpacing: 3,
            fontWeight: 500,
            userSelect: "none",
            textTransform: "uppercase",
            marginLeft: "10px",
          }}
        >
          Synced with HS
        </span>
        <Autocomplete
          id="autocomplete-syncedWithHubspot"
          name="autocomplete-syncedWithHubspot"
          options={syncedWithHubspotAutocompleteData}
          value={filters.selectedSyncedWithHubspot}
          onChange={(e, value) => {
            handleFilterChange("selectedSyncedWithHubspot", value);
            reloadWeekCalendar();
          }}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              <div
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 2,
                  fontSize: 11,
                }}
              >
                {option}
              </div>
            </li>
          )}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid black",
                  },
                  "& .css-dk8gfa-MuiSvgIcon-root-MuiSelect-icon": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "150px",
                }}
                id="selectedSyncedWithHubspot"
                name="selectedSyncedWithHubspot"
                fullWidth
                variant="standard"
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderFiltersContainer = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 50,
          margin: "-15px 0 15px 15px",
          width: "100%",
        }}
      >
        {renderBrandAutoselect()}
        {renderCategoryAutoselect()}
        {renderSeasonAutoselect()}
        {renderLocationAutoselect()}
        {renderMeetingTypeAutoselect()}
        {renderSyncedWithHubspotAutoselect()}
        {renderFromDateAutoselect()}
        {renderToDateAutoselect()}
      </Stack>
    );
  };

  const renderSecondaryButtons = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* LEFT Section */}
        <Stack style={{ flexDirection: "row", gap: 10 }}>
          {/* {renderQuickSearch()} */}
          {renderListViewButton()}
          {renderCalendarTypeButton()}
        </Stack>

        {/* RIGHT Section */}
        <Stack style={{ flexDirection: "row", gap: 10 }}>
          {renderResetFiltersButton()}
          {renderExportButton()}
        </Stack>
      </Stack>
    );
  };

  const renderToolbar = () => {
    return (
      <Stack
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Stack
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            overflowY: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "lightgrey transparent",
          }}
        >
          {renderFiltersContainer()}
        </Stack>
        <Stack
          style={{
            display: "flex",
            justifyContent: "space-around",
            minHeight: "100px",
            gap: 10,
            margin: "-15px 0 15px 0",
          }}
        >
          {renderSecondaryButtons()}
        </Stack>
      </Stack>
    );
  };

  const calendarMonthRef = useRef(null);
  const calendarWeekRef = useRef(null);

  const handleDatesSet = () => {
    if (calendarMonthRef.current) {
      // Get all day elements
      const dayElements = document.querySelectorAll(".fc-daygrid-day");

      Array.from(dayElements).forEach((element) => {
        const date = element.getAttribute("data-date"); // Get date from attribute

        const bookingsArray = filters.allBookingsRows.filter(
          (booking) => booking.date === date
        );

        const foundBooking = bookingsArray.length > 0;

        // Remove existing "Clients" div before adding a new one
        const existingElement = element.querySelector(".nr-of-clients");
        if (existingElement) {
          existingElement.remove();
        }

        // If there are bookings, update background & add new div
        if (foundBooking) {
          element.style.position = "relative";
          element.style.background =
            bookingsArray.length <= 5
              ? "#DEF1CB"
              : bookingsArray.length > 5 && bookingsArray.length <= 10
              ? "#FFFCC8"
              : "#FBC8BE";

          const nrOfClientsElement = document.createElement("div");
          nrOfClientsElement.classList.add("nr-of-clients"); // Add class for easy removal
          nrOfClientsElement.textContent =
            bookingsArray.length === 1
              ? "1 Client"
              : `${bookingsArray.length} Clients`;
          nrOfClientsElement.style.fontFamily = "tmwCustomFont";
          nrOfClientsElement.style.textTransform = "uppercase";
          nrOfClientsElement.style.fontSize = "10px";
          nrOfClientsElement.style.position = "absolute";
          nrOfClientsElement.style.bottom = 0;
          nrOfClientsElement.style.paddingBottom = "5px";
          nrOfClientsElement.style.width = "100%";
          nrOfClientsElement.style.textAlign = "center";

          element.appendChild(nrOfClientsElement);
        } else {
          // Reset background if there are NO bookings
          element.style.background = "#FFFFFF"; // Or "transparent"
        }
      });
    }
  };

  // Get only events of the current month +-7 days for the cases when a week spans accross multiple months
  useEffect(() => {
    const filteredEvents = filters.allBookingsRows.filter((booking) => {
      const bookingDate = new Date(booking.date);
      const targetDate = new Date(date);

      // Calculate the start and end of the 7-day range
      const startDate = new Date(targetDate);
      startDate.setDate(targetDate.getDate() - 7);

      const endDate = new Date(targetDate);
      endDate.setDate(targetDate.getDate() + 7);

      // Include events within the 7-day range
      return bookingDate >= startDate && bookingDate <= endDate;
    });
    setRenderedEvents(filteredEvents);
  }, [date]);

  // Persist the current date
  ///////////////////////////
  useEffect(() => {
    setSavedDate(date);
  }, [date]);

  useEffect(() => {
    setLoadingWeek(true);

    if (calendarMonthRef.current || calendarWeekRef.current) {
      const calendarApi =
        calendarMonthRef.current?.getApi() || calendarWeekRef.current?.getApi();

      if (calendarApi) {
        calendarApi.gotoDate(savedDate); // Restore date when switching views
      }
    }
  }, [calendarType]);
  ///////////////////////////

  // Scroll weekly view table functionality
  ///////////////////////////
  useEffect(() => {
    if (calendarWeekRef && calendarType === calendarViewTypes.week) {
      let keyPressTimer = null;
      let isHeld = false;

      const handleKeyDown = (event) => {
        if (!calendarWeekRef.current) return;
        const arrayElement =
          calendarWeekRef.current.elRef?.current.getElementsByClassName(
            "fc-scrollgrid-liquid"
          );
        const tbodyElement =
          Array.from(arrayElement)[0]?.children[1]?.children[0]?.children[0]
            ?.children[0]?.children[0];

        if (!tbodyElement) return;

        if (event.key === "ArrowDown" || event.key === "ArrowUp") {
          if (!isHeld) {
            tbodyElement.scrollBy({
              top: event.key === "ArrowDown" ? 125 : -125,
              behavior: "smooth",
            });
          }

          if (!keyPressTimer) {
            keyPressTimer = setTimeout(() => {
              isHeld = true;
              tbodyElement.scrollTo({
                top: event.key === "ArrowDown" ? tbodyElement.scrollHeight : 0,
                behavior: "smooth",
              });
            }, 500);
          }
        }
      };

      const handleKeyUp = () => {
        clearTimeout(keyPressTimer);
        keyPressTimer = null;
        isHeld = false;
      };

      window.addEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
        clearTimeout(keyPressTimer);
      };
    }
  }, [calendarType, calendarWeekRef]);
  ///////////////////////////

  // Reset month when the calendar type changes
  // useEffect(() => {
  //   setDate(new Date());
  // }, [calendarType]);

  useEffect(() => {
    if (calendarMonthRef.current) {
      const calendarApi = calendarMonthRef.current.getApi();

      // Call handleDatesSet immediately when component mounts
      handleDatesSet();

      // Listen for month changes
      calendarApi.on("datesSet", handleDatesSet);

      return () => {
        calendarApi.off("datesSet", handleDatesSet);
      };
    }
  }, [switchedMonth, filters, calendarType]);

  useEffect(() => {
    const calendarEl = calendarMonthRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.getEventById("selectedDate")?.remove();

      calendarApi.addEvent({
        id: "selectedDate",
        start: moment().format("yyyy-MM-DD"),
      });
    }
  }, [calendarType, filters]);

  // Removing the default slots from the dom, they are customly added
  // in the component as this is the only feasible way to approach this
  useEffect(() => {
    if (calendarWeekRef && calendarType === calendarViewTypes.week) {
      const table = document.getElementsByClassName("fc-timegrid-slots");
      const tableElement = Array.from(table)[0];

      if (tableElement) {
        tableElement.remove();
      }
    }
  }, [calendarWeekRef, calendarType]);

  useEffect(() => {
    const todayButton = document.getElementsByClassName("fc-today-button")[0];

    if (todayButton && calendarWeekRef) {
      todayButton.style.cursor = "pointer";

      todayButton.addEventListener("click", () => {
        if (calendarWeekRef?.current?.calendar?.currentData?.viewTitle) {
          setCurrentWeekTitle(
            calendarWeekRef.current.calendar.currentData.viewTitle
          );
        }
      });
    }
  }, [calendarWeekRef, calendarType]);

  useEffect(() => {
    if (calendarWeekRef?.current?.calendar?.currentData?.viewTitle) {
      setCurrentWeekTitle(
        calendarWeekRef.current.calendar.currentData.viewTitle
      );
    }
  }, [calendarType, calendarWeekRef, date]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === "ArrowLeft" &&
        calendarType === calendarViewTypes.month
      ) {
        setSwitchedMonth(!switchedMonth);
        handleDatePrevMonth(); // Navigate to the previous month
      } else if (
        event.key === "ArrowRight" &&
        calendarType === calendarViewTypes.month
      ) {
        setSwitchedMonth(!switchedMonth);
        handleDateNextMonth(); // Navigate to the next month
      } else if (
        event.key === "ArrowLeft" &&
        calendarType === calendarViewTypes.week
      ) {
        handleDatePrevWeek(); // Navigate to the previous week
      } else if (
        event.key === "ArrowRight" &&
        calendarType === calendarViewTypes.week
      ) {
        handleDateNextWeek(); // Navigate to the next week
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [calendarType, switchedMonth]);

  const handleDatePrevMonth = useCallback(() => {
    const calendarEl = calendarMonthRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDateNextMonth = useCallback(() => {
    const calendarEl = calendarMonthRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDatePrevWeek = useCallback(() => {
    setLoadingWeek(true);
    const calendarEl = calendarWeekRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDateNextWeek = useCallback(() => {
    setLoadingWeek(true);
    const calendarEl = calendarWeekRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  }, []);

  const reloadWeekCalendar = () => {
    // setLoadingWeek(true);
    const calendarEl = calendarWeekRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      setDate(calendarApi.getDate());
    }
  };

  const renderMonthlyCalendar = () => {
    return (
      <Stack
        style={{
          width: "100%",
          height: "100%",
          marginLeft: "10px",
          marginTop: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 20,
        }}
      >
        <CalendarToolbar
          startingDate={null}
          date={date}
          onDateNext={() => {
            setSwitchedMonth(!switchedMonth);
            handleDateNextMonth();
          }}
          onDatePrev={() => {
            setSwitchedMonth(!switchedMonth);
            handleDatePrevMonth();
          }}
        />
        <CalendarContainer>
          <FullCalendar
            dayHeaderFormat={{ weekday: "narrow" }}
            allDayMaintainDuration
            dayMaxEventRows={3}
            eventDisplay="block"
            eventResizableFromStart
            headerToolbar={false}
            height={mobileView ? 450 : 450}
            aspectRatio={0.75}
            firstDay={1}
            // initialDate={moment(location?.availability[0].day)
            //   .add(timezoneOffset, "hours")
            //   .format("yyyy-MM-DD")}
            // selectConstraint={{
            //   start: moment(moment().add(timezoneOffset, "hours")).isAfter(
            //     moment(location?.availability[0].day).add(
            //       timezoneOffset,
            //       "hours"
            //     )
            //   )
            //     ? moment(moment().add(timezoneOffset, "hours")).subtract(
            //         1,
            //         "day"
            //       )
            //     : moment(location?.availability[0].day).subtract(1, "day"),
            //   end: moment(location?.availability.slice(-1)[0].day).add(
            //     1,
            //     "day"
            //   ),
            // }}
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              listPlugin,
              timeGridPlugin,
            ]}
            ref={calendarMonthRef}
            rerenderDelay={10}
            weekends
            selectable
            // unselect={handleUnselect}
            // dateClick={(e) => {
            //   const filteredEvents = renderedEvents.filter(
            //     (event) =>
            //       moment(event.date).format("DD-MM-YYYY") ===
            //       moment(e.date).format("DD-MM-YYYY")
            //   );
            // }}
          />
        </CalendarContainer>
      </Stack>
    );
  };

  const renderWeeklyCalendarNavigation = () => {
    return (
      <Stack
        style={{
          zIndex: 900,
          marginBottom: "-50px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "350px",
        }}
      >
        <ButtonBase
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        >
          <ArrowDropDown
            onClick={handleDatePrevWeek}
            style={{
              color: "black",
              transform: "scale(1, 1.7)",
              rotate: "90deg",
            }}
          />
        </ButtonBase>
        <Stack
          style={{
            margin: "0 10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <p
            style={{
              fontFamily: "tmwCustomFont",
              letterSpacing: 3,
              fontSize: mobileView ? 13 : 14,
              textTransform: "uppercase",
              userSelect: "none",
            }}
          >
            {currentWeekTitle}
          </p>
        </Stack>
        <ButtonBase
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        >
          <ArrowDropDown
            onClick={handleDateNextWeek}
            style={{
              color: "black",
              transform: "scale(1, 1.7)",
              rotate: "-90deg",
            }}
          />
        </ButtonBase>
      </Stack>
    );
  };

  const generateTimeSlots = () => {
    const slots = [];
    let currentTime = new Date(2025, 0, 1, 9, 0);
    const endTime = new Date(2025, 0, 1, 18, 0);

    while (currentTime <= endTime) {
      slots.push(new Date(currentTime)); // Add time slot
      currentTime.setMinutes(currentTime.getMinutes() + 30); // Increment by 30 minutes
    }

    return slots;
  };

  const renderBookingDateInput = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <MobileDatePicker
          inputFormat="dd-MM-yyyy"
          onChange={(date) => {
            setBookingDate(date);
          }}
          renderInput={(inputProps) => (
            <CustomTextField
              style={{ width: "36%" }}
              label="date"
              inputProps={inputProps}
              calendar
              value={bookingDate}
              handleChange={(e) => {
                setBookingDate(e.target.value);
              }}
            />
          )}
          value={bookingDate}
        />
      </LocalizationProvider>
    );
  };

  const renderBookingTimeInput = () => {
    const timeSlots = generateTimeSlots();
    const formattedSlots = timeSlots.map((slot) =>
      moment(slot).format("HH:mm")
    );

    return (
      <Autocomplete
        id="autocomplete-edit-time-selection"
        name="autocomplete-edit-time-selection"
        disableClearable
        options={formattedSlots}
        value={bookingTime}
        onChange={(_, time) => {
          setBookingTime(time);
        }}
        PopperComponent={({ style, ...props }) => (
          <Popper {...props} style={{ ...style, height: 0 }} />
        )}
        ListboxProps={{
          style: {
            fontFamily: "tmwCustomFont",
            fontSize: 13,
            borderRadius: 0,
            border: "none",
            boxShadow: "none",
          },
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "black", // Changes the arrow color to black
          },
        }}
        renderInput={(inputParams) => {
          return (
            <Stack>
              <span
                style={{
                  fontFamily: "tmwCustomFont",
                  fontSize: 11,
                  color: "black",
                  letterSpacing: 3,
                  fontWeight: 500,
                  userSelect: "none",
                  textTransform: "uppercase",
                }}
              >
                Time
              </span>
              <TextField
                {...inputParams}
                variant="standard"
                sx={{
                  "& .MuiFormLabel-root": {
                    fontFamily: "tmwCustomFont",
                    textTransform: "uppercase",
                    fontSize: 12,
                    letterSpacing: 3,
                    fontWeight: 500,
                    color: "black",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid #333",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "165px",
                }}
              />
            </Stack>
          );
        }}
      />
    );
  };

  const renderBookingLocationInput = () => {
    return (
      <Autocomplete
        id="autocomplete-amend-location-selection"
        name="autocomplete-amend-location-selection"
        disableClearable
        options={allLocations}
        value={bookingLocation}
        onChange={(_, location) => {
          setBookingLocation(location);
        }}
        PopperComponent={({ style, ...props }) => (
          <Popper {...props} style={{ ...style, height: 0 }} />
        )}
        ListboxProps={{
          style: {
            fontFamily: "tmwCustomFont",
            fontSize: 13,
            borderRadius: 0,
            border: "none",
            boxShadow: "none",
          },
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "black", // Changes the arrow color to black
          },
        }}
        renderInput={(inputParams) => {
          return (
            <Stack>
              <span
                style={{
                  fontFamily: "tmwCustomFont",
                  fontSize: 11,
                  color: "black",
                  letterSpacing: 3,
                  fontWeight: 500,
                  userSelect: "none",
                  textTransform: "uppercase",
                }}
              >
                Location
              </span>
              <TextField
                {...inputParams}
                variant="standard"
                sx={{
                  "& .MuiFormLabel-root": {
                    fontFamily: "tmwCustomFont",
                    textTransform: "uppercase",
                    fontSize: 12,
                    letterSpacing: 3,
                    fontWeight: 500,
                    color: "black",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid #333",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "165px",
                }}
              />
            </Stack>
          );
        }}
      />
    );
  };

  const renderBookingMeetingTypeInput = () => {
    return (
      <Autocomplete
        id="autocomplete-edit-time-selection"
        name="autocomplete-edit-time-selection"
        disableClearable
        options={["Showroom", "Online"]}
        value={bookingMeetingType}
        onChange={(_, meetingType) => {
          setBookingMeetingType(meetingType);
        }}
        PopperComponent={({ style, ...props }) => (
          <Popper {...props} style={{ ...style, height: 0 }} />
        )}
        ListboxProps={{
          style: {
            fontFamily: "tmwCustomFont",
            fontSize: 13,
            borderRadius: 0,
            border: "none",
            boxShadow: "none",
          },
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "black", // Changes the arrow color to black
          },
        }}
        renderInput={(inputParams) => {
          return (
            <Stack>
              <span
                style={{
                  fontFamily: "tmwCustomFont",
                  fontSize: 11,
                  color: "black",
                  letterSpacing: 3,
                  fontWeight: 500,
                  userSelect: "none",
                  textTransform: "uppercase",
                }}
              >
                Meeting Type
              </span>
              <TextField
                {...inputParams}
                variant="standard"
                sx={{
                  "& .MuiFormLabel-root": {
                    fontFamily: "tmwCustomFont",
                    textTransform: "uppercase",
                    fontSize: 12,
                    letterSpacing: 3,
                    fontWeight: 500,
                    color: "black",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #E6E6E6",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottom: "2px solid #333",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "tmwCustomFont",
                    paddingLeft: "10px",
                  },
                  padding: "10px 0",
                  width: "165px",
                }}
              />
            </Stack>
          );
        }}
      />
    );
  };

  const handleOpenAmendBookingModal = (event) => {
    setCurrentBooking(event);
    setBookingAppointmentId(event.appointmentId);
    setBookingDate(event.date);
    setBookingTime(event.time);
    setBookingLocation(event.location);
    setBookingMeetingType(event.meetingType);
    setOpenAmendBookingModal(true);
  };

  const renderAmendBookingModal = () => {
    const handleCancelAmendBooking = () => {
      setCurrentBooking(null);
      setBookingAppointmentId(null);
      setBookingDate(null);
      setBookingTime(null);
      setBookingLocation(null);
      setBookingMeetingType(null);
      setOpenAmendBookingModal(false);
    };

    const handleSubmitAmendBooking = async () => {
      const changedValues = [];
      if (bookingDate !== currentBooking.date) {
        changedValues.push({ field: "date", value: bookingDate });
      }
      if (bookingTime !== currentBooking.time) {
        changedValues.push({ field: "time", value: bookingTime });
      }
      if (bookingLocation !== currentBooking.location) {
        changedValues.push({ field: "location", value: bookingLocation });
      }
      if (bookingMeetingType !== currentBooking.meetingType) {
        changedValues.push({ field: "meetingType", value: bookingMeetingType });
      }

      await dispatch(
        thunks.updateBooking(userId, changedValues, bookingAppointmentId)
      );

      handleCancelAmendBooking();
    };

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backdropFilter: "blur(5px)",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
            minWidth: "700px",
          }}
        >
          <Container>
            <Stack
              elevation={12}
              style={{
                padding: "25px 25px 50px 25px",
                background: "white",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack style={{ gap: 35 }}>
                <Stack style={{ alignItems: "center" }}>
                  <CustomTypography title message="Amend Booking" />
                </Stack>
                <Stack
                  style={{
                    alignItems: "center",
                    gap: 15,
                  }}
                >
                  <Stack
                    style={{
                      alignItems: "center",
                      justifyContent: "space-around",
                      flexDirection: "row",
                      width: "75%",
                    }}
                  >
                    {renderBookingDateInput()}
                    {renderBookingTimeInput()}
                  </Stack>
                  <Stack
                    style={{
                      alignItems: "center",
                      justifyContent: "space-around",
                      flexDirection: "row",
                      width: "75%",
                    }}
                  >
                    {renderBookingLocationInput()}
                    {renderBookingMeetingTypeInput()}
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 10,
                  margin: "35px 0 -15px 0",
                }}
              >
                <CustomButton
                  label="Cancel"
                  redButton
                  smallButton
                  onClick={handleCancelAmendBooking}
                />
                <CustomButton
                  label="Submit"
                  disabled={allBookingsLoading}
                  loader={
                    allBookingsLoading && (
                      <CircularProgress
                        color="inherit"
                        size={10}
                        style={{ marginLeft: "4px" }}
                      />
                    )
                  }
                  greenButton
                  smallButton
                  onClick={handleSubmitAmendBooking}
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const handleOpenDeleteBookingModal = (event) => {
    setCurrentBooking(event);
    setOpenDeleteBookingModal(true);
  };

  const renderDeleteBookingButton = (handleCancelDeleteBooking) => {
    const handleDeleteBooking = async () => {
      await dispatch(
        thunks.deleteBooking(userId, currentBooking.appointmentId)
      );
      handleCancelDeleteBooking();
    };

    return (
      <Button
        onMouseEnter={() => setHoveredDeleteBookingButton(true)}
        onMouseLeave={() => setHoveredDeleteBookingButton(false)}
        style={{
          fontFamily: "tmwCustomFont",
          textTransform: "uppercase",
          borderRadius: "25px",
          border: allBookingsLoading ? "1px solid #E6E6E6" : "1px solid red",
          transition: "ease-out 0.15s",
          letterSpacing: 2,
          fontSize: 11,
          userSelect: "none",
          fontWeight: 400,
          color: allBookingsLoading
            ? "#E6E6E6"
            : hoveredDeleteBookingButton
            ? "white"
            : "red",
          background: allBookingsLoading
            ? "white"
            : hoveredDeleteBookingButton
            ? "red"
            : "white",
        }}
        disabled={allBookingsLoading}
        onClick={handleDeleteBooking}
      >
        Delete
        {allBookingsLoading && (
          <CircularProgress
            color="inherit"
            size={14}
            style={{ marginLeft: "4px" }}
          />
        )}
      </Button>
    );
  };

  const renderDeleteBookingModal = () => {
    const handleCancelDeleteBooking = () => {
      setCurrentBooking(null);
      setOpenDeleteBookingModal(false);
    };

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(5px)",
          zIndex: 1100,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1100,
          }}
        >
          <Container>
            <Stack
              elevation={12}
              style={{
                padding: "25px 25px 50px 25px",
                background: "white",
                border: "1px solid #E6E6E6",
              }}
            >
              <Stack
                style={{
                  padding: "15px 15px 0 15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={tmwWarningIcon}
                />
                <CustomTypography message="Are you sure you want to delete this appointment?" />
              </Stack>
              <Stack
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: 25,
                  margin: "20px 0 -15px 0",
                }}
              >
                {renderDeleteBookingButton(handleCancelDeleteBooking)}
                <CustomButton
                  label="Cancel"
                  onClick={() => handleCancelDeleteBooking()}
                />
              </Stack>
            </Stack>
          </Container>
        </Box>
      </div>
    );
  };

  const renderWeeklyCalendar = () => {
    return (
      <Stack
        style={{
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100%",
          width: "100%",
        }}
      >
        {renderWeeklyCalendarNavigation()}
        <Stack
          style={{
            width: !mdUp ? "70vw" : "100%", // Increased width for larger screens
            height: "100%",
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 20,
          }}
        >
          <CalendarContainer
            sx={{
              position: "relative",
              marginTop: "-20px",
              // THE TOP THREE SECTIONS (EACH)
              ".fc-toolbar-chunk": {
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
              },
              // WEEK VIEW
              width: "95vw",
              padding: "0 25px",
              height: "90%",
              ".fc .fc-timegrid": {
                border: "1px solid #E6E6E6",
                overflow: "visible !important",
              },
              // BORDER BETWEEN HEADER DAYS
              ".fc-col-header-cell .fc-scrollgrid-sync-inner": {
                borderRight: "1px solid lightgrey",
              },
              ".fc-col-header-cell:last-child .fc-scrollgrid-sync-inner": {
                borderRight: "none",
              },
              // BORDER BETWEEN TIMESLOTS
              ".fc .fc-day": {
                borderRight: "1px solid lightgrey",
              },
              // TODAY
              ".fc-day-today": { background: "#FFFCC8" },
              ".fc-day-today .fc-timegrid-col-frame": {
                background: "white",
              },

              ".fc-media-screen": {
                minHeight: "100%",
              },
              ".fc-daygrid-day-frame.fc-scrollgrid-sync-inner": {
                opacity: "100% !important",
                cursor: "pointer",
              },
              ".fc-daygrid-day-frame.fc-scrollgrid-sync-inner  .fc-daygrid-day-top":
                {
                  opacity: "100% !important",
                },
              ".fc-day-other .fc-daygrid-day-frame.fc-scrollgrid-sync-inner": {
                opacity: "0% !important",
                cursor: "default !important",
              },
              ".fc-timegrid-slot": {
                height: "50px", // You can adjust the height as needed
              },
              // TIME LABELS ON THE LEFT
              ".fc-scrollgrid-shrink": {
                display: "none", // Hides only the time labels on the left
              },
              // TODAY BUTTON
              ".fc-today-button": {
                backgroundColor: "white", // Change background color
                color: "black", // Change text color
                border: "1px solid #E6E6E6", // Remove border
                borderRadius: "25px",
                padding: "8px 20px",
                fontFamily: "tmwCustomFont",
                textTransform: "uppercase",
                fontSize: 10,
                transition: "ease-out 0.15s",
                letterSpacing: 2,
                userSelect: "none",
                fontWeight: 400,
                cursor: "pointer", // Change cursor to pointer
                marginBottom: "-10px",
              },
              ".fc-today-button:hover": {
                backgroundColor: "#E6E6E6", // Change background on hover
                color: "black",
                borderColor: "#E6E6E6",
              },
              ".fc-today-button:focus": {
                backgroundColor: "white", // Change background on hover
                color: "black",
                boxShadow: "none",
                borderColor: "#E6E6E6",
              },
              ".fc-today-button:active": {
                backgroundColor: "#E6E6E6 !important", // Change background on hover
                color: "black !important",
                boxShadow: "none !important",
                borderColor: "#E6E6E6 !important",
              },
              ".fc-today-button:disabled": {
                backgroundColor: "#E6E6E6", // Change background for disabled state
                borderColor: "#E6E6E6",
                color: "black",
                opacity: "100%",
                cursor: "default",
              },
              // TITLE
              ".fc-toolbar-title": {
                fontFamily: "tmwCustomFont",
                textTransform: "uppercase",
                fontSize: 16,
                fontWeight: 400,
                letterSpacing: 2,
              },
            }}
          >
            {/* Loader indicator */}
            {loadingWeek && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 3000,
                  background: "white",
                  width: "100%",
                  height: "100%",
                  top: "45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="inherit" size={35} />
              </div>
            )}
            <FullCalendar
              initialView="timeGridWeek"
              allDaySlot={false}
              allDayMaintainDuration
              customButtons={{
                today: {
                  text: "Current week", // Custom text
                  click: function () {
                    setLoadingWeek(true);
                    setDate(new Date());

                    calendarWeekRef.current.getApi().today();
                  },
                },
              }}
              headerToolbar={{
                start: "",
                center: "",
                end: "today",
              }}
              datesSet={() => {
                setLoadingWeek(false);
              }}
              dayHeaderContent={(args) => {
                const clients = renderedEvents.filter(
                  (event) =>
                    event.date &&
                    moment(event.date).format("DD-MM-YYYY") ===
                      moment(args.date).format("DD-MM-YYYY")
                );
                const nrOfClients = clients.length;
                const nrOfClientsString = `${nrOfClients} Client${
                  nrOfClients !== 1 ? "s" : ""
                }`;

                // Customize the text content for the headers
                return (
                  <Stack
                    style={{
                      fontFamily: "tmwCustomFont",
                      color: "black",
                      alignItems: "center",
                      justifyContent: "center",
                      userSelect: "none",
                    }}
                  >
                    <Stack style={{ fontSize: 11 }}>
                      {moment(args.date).format("dddd")}
                    </Stack>
                    <Stack
                      style={{
                        fontSize: 16,
                        textTransform: "uppercase",
                        textDecoration: "underline",
                      }}
                    >
                      {moment(args.date).format("D MMMM YYYY")}
                    </Stack>
                    <Stack
                      style={{
                        fontSize: 11,
                        textTransform: "uppercase",
                      }}
                    >
                      {nrOfClientsString}
                    </Stack>
                  </Stack>
                );
              }}
              slotMinTime="09:00" // Set the start time to 9:00 AM
              slotMaxTime="18:30" // Set the end time to 6:00 PM
              slotDuration="00:30"
              slotLabelFormat={[
                { month: "long", year: "numeric" }, // top level of text
                { weekday: "short" }, // lower level of text
              ]}
              slotLabelInterval="00:30:00"
              dayCellContent={(args) => {
                const currentDate = moment(args.date).format("DD-MM-YYYY");

                // Example custom text based on the current day
                const timeSlots = generateTimeSlots();

                return timeSlots.map((slot, index) => {
                  const formattedSlot = moment(slot).format("HH:mm");
                  const filteredEvents = renderedEvents.filter(
                    (event) =>
                      moment(event.date).format("DD-MM-YYYY") === currentDate &&
                      event.time === formattedSlot
                  );

                  return (
                    <Stack
                      key={index}
                      style={{
                        fontFamily: "tmwCustomFont",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottom: "1px solid #E6E6E6",
                        paddingTop: "10px",
                        paddingBottom: !filteredEvents.length && "10px",
                        // color:
                        //   currentDate === moment().format("DD-MM-YYYY")
                        //     ? "black"
                        //     : "lightgrey",
                        background: filteredEvents.length && "#FFFCC8",
                        width: "100%",
                      }}
                    >
                      <Stack
                        style={{
                          fontSize: 12,
                          letterSpacing: 1,
                        }}
                      >
                        {formattedSlot}
                      </Stack>
                      {filteredEvents.map((event, index) => {
                        if (
                          moment(event.date).format("DD-MM-YYYY") ===
                            currentDate &&
                          event.time === formattedSlot
                        ) {
                          const key = `event-${event.date}-${event.time}-${index}`;
                          return (
                            <Stack
                              key={key}
                              style={{
                                position: "relative",
                                width: "100%",
                                marginTop: index === 0 && "10px",
                                padding: "5px 5px 6px 5px",
                                borderTop: "1px dashed #E6E6E6",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                background: "#FFFCC8",
                                maxHeight: "78px",
                                minHeight: "78px",
                              }}
                            >
                              <Stack
                                style={{
                                  fontSize: 10,
                                  position:
                                    hoveredAppointmentIndex === key &&
                                    "absolute",
                                  overflow:
                                    hoveredAppointmentIndex === key
                                      ? "visible"
                                      : "hidden",
                                  background:
                                    hoveredAppointmentIndex === key &&
                                    "#FFFCC8",
                                  border:
                                    hoveredAppointmentIndex === key &&
                                    "0.75px solid black",
                                  zIndex:
                                    hoveredAppointmentIndex === key && 3500,
                                  minHeight: "78px",
                                  maxHeight:
                                    hoveredAppointmentIndex === key
                                      ? ""
                                      : "78px",
                                  width: "100%",
                                  padding:
                                    hoveredAppointmentIndex === key &&
                                    "2px 5px",
                                  transform:
                                    hoveredAppointmentIndex === key &&
                                    "scale(1.2)",
                                  paddingLeft:
                                    hoveredAppointmentIndex === key && "10%",
                                  transition: "transform 0.15s ease-in-out",
                                  userSelect:
                                    hoveredAppointmentIndex === key && "text",
                                }}
                                onMouseEnter={() => {
                                  setHoveredAppointmentIndex(key);
                                }}
                                onMouseLeave={() => {
                                  setHoveredAppointmentIndex(null);
                                }}
                              >
                                <Stack
                                  style={{
                                    fontWeight: 600,
                                    paddingTop:
                                      hoveredAppointmentIndex !== key && "5px",
                                  }}
                                >
                                  {event.storeName || "No store"}
                                </Stack>
                                <Stack>
                                  {hoveredAppointmentIndex === key
                                    ? event?.brands?.join(", ")
                                    : event?.brands[0]}
                                  {hoveredAppointmentIndex !== key &&
                                    event?.brands?.length > 1 &&
                                    " ..."}
                                </Stack>
                                <Stack>
                                  {hoveredAppointmentIndex === key
                                    ? event?.categories?.join(", ")
                                    : event?.categories?.length
                                    ? event?.categories[0]
                                    : null}
                                  {hoveredAppointmentIndex !== key &&
                                    event?.categories?.length > 1 &&
                                    " ..."}
                                </Stack>
                                <Stack>{event.location}</Stack>
                              </Stack>
                              <Stack
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: 10,
                                }}
                              >
                                <CustomTooltip title={event.meetingType}>
                                  <Stack
                                    style={{
                                      zIndex: 10,
                                    }}
                                  >
                                    {event.meetingType === "Showroom" ? (
                                      <img
                                        style={{
                                          width: "17px",
                                          height: "17px",
                                        }}
                                        src={tmwShop}
                                      />
                                    ) : event.meetingType === "Online" ? (
                                      <img
                                        style={{
                                          width: "17px",
                                          height: "17px",
                                        }}
                                        src={tmwOnlineMeeting}
                                      />
                                    ) : null}
                                  </Stack>
                                </CustomTooltip>
                                <Stack
                                  style={{
                                    gap: 5,
                                    cursor: "pointer",
                                  }}
                                >
                                  <CustomButton
                                    labelStyle={{ fontSize: 6 }}
                                    style={{
                                      padding: "3px",
                                      background: "white",
                                    }}
                                    label="Amend"
                                    onClick={() => {
                                      handleOpenAmendBookingModal(event);
                                    }}
                                  />
                                  <CustomButton
                                    labelStyle={{ fontSize: 6 }}
                                    style={{
                                      padding: "3px",
                                      background: "white",
                                    }}
                                    label="Delete"
                                    onClick={() => {
                                      handleOpenDeleteBookingModal(event);
                                    }}
                                  />
                                </Stack>
                              </Stack>
                            </Stack>
                          );
                        }
                      })}
                    </Stack>
                  );
                });
              }}
              dayMaxEventRows={3}
              eventDisplay="block"
              eventResizableFromStart
              height={mobileView ? 450 : 450}
              aspectRatio={1.5} // Adjusted aspect ratio for better width utilization
              firstDay={1}
              plugins={[dayGridPlugin, timeGridPlugin]}
              ref={calendarWeekRef}
              rerenderDelay={10}
              weekends
              selectable
            />
          </CalendarContainer>
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <Seo title="Booking: All Bookings" />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          px: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 -15px -50px 0",
            padding: "0 30px",
          }}
        >
          {renderToolbar()}
        </Stack>
        <Stack
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {calendarType === calendarViewTypes.month
            ? renderMonthlyCalendar()
            : renderWeeklyCalendar()}
        </Stack>
        {openAmendBookingModal && renderAmendBookingModal()}
        {openDeleteBookingModal && renderDeleteBookingModal()}
      </Box>
    </>
  );
};

export default withBookingSystemGuard(CalendarViewPage);
